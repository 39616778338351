<template>
  <div class="kanban-board">
    <b-container style="min-height: 1000px">
      <b-card :title="msg('Pick Property')" class="mb-3">
        <b-row class="justify-content-end mb-1">
          <b-col cols="9">
            <real-estate-picker v-model="propertyId"/>
          </b-col>
          <b-col cols="3">
            <b-button :disabled="propertyId==null" @click="addList" variant="primary">{{ msg('Add List') }}</b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-row class="real-estate-row" ref="realEstateRow">
        <b-col v-for="realEstate in realEstates" :key="realEstate.id" cols="3">
          <b-card @click="selectProperty(realEstate.id)" class="real-estate-card" style="cursor: pointer;padding: 0"
                  :class="{ 'selected-card': propertyId === realEstate.id }">

            <b-row>
              <b-col cols="3">
                <b-img
                    v-bind="{ blank: (realEstate.attachments == null || realEstate.attachments[0] == null), blankColor: '#777', width: 50, height: 50, src: (realEstate.attachments != null && realEstate.attachments[0] != null ? mergeSrcUrlCustom(realEstate.attachments[0]) : null) }"
                    blank-color="#475F7B"
                    rounded
                    alt="Image"
                    class="d-inline-block"
                />
              </b-col>
              <b-col cols="9">
                <span class="card-title">{{ realEstate.address }}</span>
                <p class="card-title">{{ realEstate.house }} {{ realEstate.apt }}</p>
                <p class="card-text">{{ realEstate.zip }} {{ realEstate.city }} {{ realEstate.country }}</p>
                <b-badge variant="warning">{{getListLength(realEstate.id) }} {{msg('activelists')}}</b-badge>
              </b-col>

            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <div class="break-line pb-4 pt-2">
        <b-badge style="font-size: 20px" variant="danger">Kanban Board</b-badge>
      </div>
      <div v-if="!loading&& lists!=null && lists.length>0" class="lists-container">
        <div class="list mb-2" v-for="(list, listIndex) in lists" :key="listIndex" :ref="`list-${listIndex}`">
          <div class="list-title" style="position: relative;">
            <div class="list-color-pill" :style="{ backgroundColor: list.color }"></div>
            <div class="list-header" @dblclick="openEditListModal(listIndex)">
              {{ list.title }}
            </div>
            <!-- Delete icon for deleting the entire list -->
            <i class="fas fa-trash-alt delete-list-icon" @click="deleteList(listIndex)"
               style="position: absolute; top: 5px; right: 5px; color: #804e4e; cursor: pointer;"></i>
          </div>
          <div v-if="list.cards!=null && list.cards.length>0" class="mt-2">
            <b-card
                class="card2"
                v-for="(card, cardIndex) in list.cards"
                :key="cardIndex"
                style="box-shadow: 0px 2px 4px rgb(133,68,68); padding-left: 0;"
                :draggable="true"
                @dragstart="dragStart(listIndex, cardIndex)"
                @dragover.prevent
                @drop="drop(listIndex, cardIndex)"
            >
              <!-- Edit icon -->
              <div class="actions">
                <i
                    class="fas fa-pencil-alt edit-icon"
                    @click="editCard(card, listIndex)"
                    style="color: #c2aa8e; cursor: pointer; position: absolute; top: 5px; right: 3px;"
                ></i>
                <i
                    class="fas fa-trash-alt"
                    @click="deleteCard(card, listIndex)"
                    style="color: #804e4e; cursor: pointer; position: absolute; top: 5px; right: 25px;"
                ></i>
              </div>

              <div v-for="(detail, detailIndex) in card.details" :key="detailIndex"
                   style="font-size: 14px;margin-bottom: 10px">
                <!-- For TEXT type -->
                <div v-if="detail.type === 'TEXT'" class="text-style">
                  <p class="overflow-text">{{ detail.value }}</p>
                </div>

                <!-- For HYPERLINK type -->
                <div v-else-if="detail.type === 'HYPERLINK'" class="hyperlink-style">
                  <b-link :href="normalizeUrl(detail.value)" target="_blank">
                    <i class="fas fa-globe linkStyle"> {{ detail.value }} </i>
                  </b-link>
                </div>

                <div v-else-if="detail.type === 'FILE' && isImage(detail.value)" style="cursor: pointer"
                     @click="openImageModal(detail)">
                  <!-- Display image -->
                  <img width="100px" height="100px" :src="detail.value" alt="File Preview"/>
                </div>

                <div v-else-if="detail.type === 'FILE' && detail != null && detail.value != null">
                  <b-button variant="secondary" @click="openFileInNewTab(detail.value, detail.filename)">
                    <i v-bind:class="iconByExt(detail.filename)" class="mr-1 pb-0" style="font-size: 20px;">
                    </i>{{ detail.filename }}
                  </b-button>
                </div>
              </div>

              <div style="position: absolute; bottom: 5px; right: 5px; color: #888; font-size: 9px;">
                {{ msg('Last updated') + ' ' + $moment(card.created).format('DD.MM.YYYY HH:mm:ss') }}
              </div>
            </b-card>
          </div>

          <div class="new-card-container" @click="openNewCardModal(listIndex)" @dragover.prevent @drop="drop(listIndex, null)">
            <div class="new-card">+ New Card</div>
          </div>

        </div>
      </div>
    </b-container>
    <b-modal v-model="showNewCardModal" :title="selectedListTitle+' -  New Card'" no-close-on-backdrop hide-footer>
      <b-row class="mb-1">
        <b-col md="12">
          <b-form-group label="Select Object Type">
            <b-form-select v-model="selectedObjectType" :options="objectTypes"></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="selectedObjectType === 'TEXT' || selectedObjectType === 'HYPERLINK'">
        <b-col>
          <!-- Input for adding text content -->
          <b-form-textarea v-model="newDetail.value" placeholder="Enter text content"></b-form-textarea>
        </b-col>
      </b-row>

      <b-row v-else-if="selectedObjectType === 'FILE'">
        <b-col>
          <!-- Dropzone for image or document upload -->
          <input
              type="file"
              @change="handleFileUpload"
              accept="image/*, .pdf, .doc, .docx"
          />
        </b-col>
      </b-row>

      <b-row class="mt-3" v-if="newCard.length>0">
        <div>
          <ul>
            <li v-for="(card, index) in newCard" :key="index">
              <div class="card-item">
                <template v-if="card.type === 'TEXT'">
                  <!-- Print text -->
                  <p>{{ card.value }}</p>
                </template>

                <template v-else-if="card.type === 'HYPERLINK'">
                  <!-- Print hyperlink -->
                  <a :href="card.value" target="_blank">{{ card.value }}</a>
                </template>

                <template v-else-if="card.type === 'FILE'">
                  <!-- Print filename for file -->
                  <p><i v-bind:class="iconByExt(card.filename)" class=""
                        style="font-size: 20px;margin-right: 5px"></i>{{ card.filename }}</p>
                </template>
              </div>
              <hr> <!-- Line break between items -->
            </li>
          </ul>
        </div>

      </b-row>
      <b-row class="mt-2">
        <b-col cols="4">
          <b-button
              :disabled="selectedObjectType==null ||selectedObjectType==='FILE' || newDetail.value==null || newDetail.value===''"
              @click="addToCard" variant="primary">Add to card
          </b-button>
        </b-col>
        <b-col cols="4">
        </b-col>
        <b-col cols="4">
          <b-button :disabled="newCard.length<1" @click="saveNewCard" variant="success">SAVE card</b-button>
        </b-col>

      </b-row>

    </b-modal>
    <b-modal v-model="showEditListModal" title="Edit List" hide-footer>
      <b-form-group label="New List Title">
        <b-form-input v-model="editedListTitle"></b-form-input>
      </b-form-group>

      <b-row class="mb-2 text-center">
        <b-col cols="3"></b-col>
        <b-col cols="6">

          <color-picker
              v-model="selectedColor"
              format="hex"
          ></color-picker>
        </b-col>
        <b-col cols="3"></b-col>

      </b-row>

      <b-button @click="saveListTitle" variant="primary">Save</b-button>
    </b-modal>
    <FileModal @close="closeImageModal"
               :fileContent="base64FileContent" :fileName="fileName" :show-image-modal="showImageModal"/>

  </div>
</template>
<script>
import RealEstatePicker from "@/components/components/picker/realEstatesPicker";
import {mapActions} from "vuex";
import {Sketch} from 'vue-color';
import FileModal from "@/views/realEstate/kanban/fileModal";

export default {

  name: 'Dashboard',
  components: {RealEstatePicker, ColorPicker: Sketch, FileModal},
  data() {
    return {
      loading: true,
      base64FileContent: null,
      fileName: null,
      showImageModal: false,
      propertyId: null,
      newCard: [],
      showNewCardModal: false,
      selectedObjectType: 'TEXT',
      selectedColor: '#FF0000', // Initial color for the list title
      objectTypes: ['TEXT', 'HYPERLINK', 'FILE'],
      newDetail: {
        value: null,
        type: null,
        filename: null,
        created: null,
      },
      selectedListTitle: null,
      showEditListModal: false,
      editedListIndex: null,
      editedListTitle: '',
      draggedCardIndex: null,
      lists: null,
      draggedItem: null,
      selectedColorIndex: -1,
      realEstates: null,
      kanbanLength: null,
    };
  },
  created() {
    this.getActiveRealEstates();
  },
  watch: {

    propertyId: {
      handler() {
        this.refresh()
      }
    }
  },
  methods: {
    ...mapActions("kanban", ["getKanbanById", "pushList", "getAllActiveRealEstates"]),
    getListLength(id) {
      return this.kanbanLength[id];
    },
    openImageModal(detail) {
      this.base64FileContent = detail.value;
      this.fileName = detail.filename;
      this.showImageModal = true;
    },
    getActiveRealEstates() {
      let $this = this;

      this.getAllActiveRealEstates().then((ans) => {
        $this.realEstates = ans["RealEstates"];
        $this.kanbanLength = ans["Kanban"];
      });
    },
    closeImageModal() {
      this.showImageModal = false;
    },
    addToCard() {
      this.newDetail.created = new Date();
      this.newDetail.type = this.selectedObjectType;

      this.newCard.push(this.newDetail);

      this.resetNewDetail();
    },
    resetNewDetail() {
      this.selectedObjectType = null;

      this.newDetail = {
        value: null,
        type: null,
        filename: null,
        created: null,
      }

    },
    saveNewCard() {

      const newCard = {
        details: this.newCard
      };
      this.lists[this.selectedListIndex].cards.push(newCard);

      this.pushListApi();

      this.newCard = [];
      this.showNewCardModal = false;
    },

    openEditListModal(listIndex) {
      this.editedListIndex = listIndex;
      this.editedListTitle = this.lists[listIndex].title;
      this.showEditListModal = true;
    },

    saveListTitle() {
      if (this.editedListIndex !== null && this.editedListTitle.trim() !== '') {
        this.lists[this.editedListIndex].title = this.editedListTitle;
        this.lists[this.editedListIndex].color = this.selectedColor.hex;
        this.pushListApi()

        this.showEditListModal = false;
      }
    },
    refresh() {
      let $this = this;
      $this.loading = true;

      this.getKanbanById(this.propertyId).then((response) => {

        $this.lists = response.lists;
        $this.loading = false;
      });
    },
    normalizeUrl(url) {
      // Check if the URL starts with a protocol
      if (!/^https?:\/\//i.test(url)) {
        // If it doesn't have a protocol, assume it's an external link and prepend 'https://'
        return `https://${url}`;
      }
      return url;
    },

    selectProperty(propertyId) {
      this.propertyId = propertyId;

      // Scroll to the selected card
      this.refresh()
    },
    openFileInNewTab(fileContent, fileName) {
      const blob = this.getBlobUrlFromBase64(fileContent);
      const objectURL = URL.createObjectURL(blob);
      window.open(objectURL, '_blank');
    },
    getBlobUrlFromBase64(base64String) {
      const base64Content = base64String.split(',')[1]; // Extract the Base64 content after the comma
      const mimeType = base64String.split(';')[0].split(':')[1];
      const byteCharacters = atob(base64Content);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      try {
        return new Blob([byteArray], {type: mimeType}); // Adjust MIME type as needed

      } catch (error) {
        console.error('Error creating blob URL:', error);
        return null;
      }
    },
    isImage(data) {
      // Check if the data is an image based on its header or mime type
      // For simplicity, assume a basic check using the image MIME types
      return /^data:image\/(jpeg|png|gif|jpg);base64,/.test(data);
    },
    getPreviewText(data) {
      if (this.isImage(data)) {
        return `<img src="${data}" alt="Image preview" />`;
      } else {
        // Logic for non-image file preview
        // For example, displaying file name or a generic file icon
        return `File: ${data.substring(0, 10)}...`;
      }
    },


    iconByExt(file) {
      if (file == null) {
        return {fas: true, 'fa-file-alt': true};
      }
      let ext = file.substr(file.lastIndexOf('.') + 1);
      if (ext === 'pdf') {
        return {fas: true, 'fa-file-pdf': true, 'text-danger': true}; // Apply text-danger class for red color
      }
      if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gif' || ext === 'bmp') {
        return {fas: true, 'fa-file-image': true, 'text-primary': true}; // Apply text-primary class for blue color
      }
      if (ext === 'doc' || ext === 'docx') {
        return {fas: true, 'fa-file-word': true, 'text-info': true}; // Apply text-info class for green color
      }
      if (ext === 'xls' || ext === 'xlsx') {
        return {fas: true, 'fa-file-excel': true, 'text-success': true}; // Apply text-success class for success color
      }
      if (ext === 'zip' || ext === 'rar' || ext === '7zip') {
        return {fas: true, 'fa-file-archive': true, 'text-warning': true}; // Apply text-warning class for warning color
      }
      return {fas: true, 'fa-file-alt': true};
    },

    openNewCardModal(listIndex) {
      this.selectedListIndex = listIndex;
      this.selectedListTitle = this.lists[listIndex].title;
      this.showNewCardModal = true;
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContentAsText = e.target.result;

        // Prepare the card object with the file content as Base64
        const fileObject = {
          type: 'FILE',
          value: fileContentAsText, // Save file content as Base64 string
          filename: file.name, // Save the original filename
          created: new Date(),
        };

        this.newCard.push(fileObject)

        this.resetNewDetail();

      };

      reader.readAsDataURL(file); // Convert file to Base64 string
    }
    ,
    addList() {
      this.lists.push({title: 'New List', cards: []});
      this.pushListApi()

    },
    pushListApi() {
      this.pushList({propertyId: this.propertyId, list: this.lists}).then((response) => {
        this.lists = response.lists;
        this.getActiveRealEstates();
      });
    },
    deleteList(index) {
      this.lists.splice(index, 1);
      this.pushListApi();

    },
    updateListTitle(index) {
      // Implement any necessary update logic here
      console.log('Updated list title:', this.lists[index].title);
    },
    dragStart(listIndex, cardIndex) {
      this.draggedListIndex = listIndex;
      this.draggedCardIndex = cardIndex;
    },
    editCard(card, listIndex) {
      this.$swal({
        title: 'Edit',
        text: 'Edit Card not possible yet',
        icon: 'danger',
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    deleteCard(card, listIndex) {
      this.lists[listIndex].cards.splice(card, 1);
      this.pushListApi();
    },

    getBlobUrl(fileContent) {
      const byteCharacters = atob(fileContent);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {type: 'application/octet-stream'});

      return URL.createObjectURL(blob);
    },
    drop(targetListIndex, targetCardIndex) {
      let draggedCard = this.lists[this.draggedListIndex].cards[this.draggedCardIndex];
      draggedCard.created = new Date();
      if (targetListIndex !== this.draggedListIndex || targetCardIndex !== this.draggedCardIndex) {
        this.lists[this.draggedListIndex].cards.splice(this.draggedCardIndex, 1);
        if (targetCardIndex === null) {
          this.lists[targetListIndex].cards.push(draggedCard);
        } else {
          this.lists[targetListIndex].cards.splice(targetCardIndex, 0, draggedCard);
        }
        this.pushListApi();

      }

      this.draggedListIndex = null;
      this.draggedCardIndex = null;
    }
  }
};
</script>
<style scoped>
/* Style for Kanban board container */
.kanban-board {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.card-item {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
}


/* Style for lists container */
.lists-container {
  display: flex;
  gap: 20px;
}

/* Style for individual lists */
.list {
  flex: 0 0 300px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 20px #c2aa8e;
}

/* Style for individual lists */
.dark-layout .list {
  flex: 0 0 300px;
  color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 20px #c2aa8e;
}

/* Style for list header */
.list-header {
  font-weight: bold;
  margin-bottom: 10px;
}

/* Style for cards */
.card2 {
  /*
  border: 1px solid rgba(255, 0, 0, 0.21);
  */
  border-radius: 3px;
  padding: 4px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dark-layout .card2 {
  border-radius: 3px;
  padding: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.card2:hover {
  background-color: rgba(255, 255, 255, 0);
}

.dark-layout .card2:hover {
  background-color: rgba(249, 249, 249, 0.15);
}

/* Style for new card placeholder */
.new-card {
  border-radius: 3px;
  height: 40px;
  padding: 8px;
  color: #666;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.new-card-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* Add any additional styling as needed */
}
.new-card:hover {
  background-color: #d0d0d0;
}

.color-button {
  width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 50%;
  border: none;
}

.list-title {
  display: flex;
  align-items: center;
}

.list-color-pill {
  width: 10px;
  margin-bottom: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.break-line {
  height: 10px;
  border: none;
  border-top: 1px solid #ddd; /* Choose your desired color */
  border-image: linear-gradient(to right, transparent, #aaa, transparent);
  margin: 15px 0; /* Adjust margin as needed */
}

.real-estate-row {
  overflow-x: auto;
  white-space: nowrap;
}
.custom-card-body {
  padding: 5px; /* Adjust the padding as needed */
}
.real-estate-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 5px #c2aa8e;
  padding: 0px 0px;
}

.real-estate-card:hover {
  transform: scale(1.03);
  box-shadow: 0px 4px 5px #c2aa8e;
}

.selected-card {
  box-shadow: 0px 4px 5px red;
}

.selected-card:hover {
  box-shadow: 0px 4px 5px red;
}

.overflow-text {
  max-width: 200px; /* Set your desired width */
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.overflow-text:hover {
  white-space: normal;
  overflow: visible;
}

.edit-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  z-index: 1; /* Ensure it's above the card content */
  color: #777; /* Set the color for the edit icon */
}

.dark-layout .text-style {
  background-color: rgba(249, 249, 249, 0.09);
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.dark-layout .hyperlink-style {
  background-color: rgba(249, 249, 249, 0.09);
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  display: inline-block; /* Ensures the hyperlink block remains inline */
}


.text-style {
  background-color: rgba(164, 117, 117, 0.28);
  border-radius: 5px;

  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.hyperlink-style {
  background-color: rgba(164, 117, 117, 0.28);
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  display: inline-block; /* Ensures the hyperlink block remains inline */
}

.linkStyle {
  color: black;
  font-size: 15px;

}

.dark-layout .linkStyle {
  color: white;
  font-size: 15px;
}

.card-text {

  font-size: 10px;
}

.card-title {

  font-size: 15px;
}
</style>
